import React, { useEffect, useState, useCallback } from "react";
import "./SplashScreen.css";
import graphic from "../../assets/graphicEq.svg";
import { FormControl, Select, MenuItem } from "@mui/material";
import { useTranslationSetup } from "../../utils/useTranslationSetup";

const SplashScreen = React.memo(({ setSplash = () => {}, organisation }) => {
  const alphabetArray = ["A", "क", "B", "ख", "C", "ग"];
  const [currentIndex, setCurrentIndex] = useState(0);
  const { t, activeLanguage, setLanguage } = useTranslationSetup();

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % alphabetArray.length);
    }, 800);

    return () => clearInterval(intervalId);
  }, []);

  const LanguageOptions = useCallback(
    () => [
      { code: "en", label: "(En) English" },
      { code: "hi", label: "(हिं) Hindi" },
    ],
    []
  );

  const handleLanguageChange = useCallback(
    (event) => {
      const selectedLang = event.target.value;
      setLanguage(selectedLang);
    },
    [setLanguage]
  );

  const getStarted = useCallback(() => {
    setSplash(false);
  }, [setSplash]);

  return (
    <div className="splashScreenDiv">
      {(organisation === "bihar_demo_1" ||
        organisation === "bihar_pilot_1" ||
        organisation === "bihar_pilot_2" ||
        organisation === "delhi_mcd_1") && (
        <div className="language-selector">
          <FormControl variant="outlined" size="small">
            <Select
              value={activeLanguage}
              onChange={handleLanguageChange}
              displayEmpty
              renderValue={(selected) =>
                LanguageOptions().find((lang) => lang.code === selected)
                  ?.label || ""
              }
              sx={{
                color: "#FFF",
                ".MuiOutlinedInput-notchedOutline": {
                  borderColor: "transparent",
                },
                ".MuiSvgIcon-root": {
                  color: "#FFF",
                  left: "108px",
                },
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: "transparent",
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: "transparent",
                },
              }}
            >
              {LanguageOptions().map((lang) => (
                <MenuItem key={lang.code} value={lang.code}>
                  {lang.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
      )}
      <div className="headingText">{t("orf")}</div>
      <div className="container">
        <div className="ellipse" id="ellipse1"></div>
        <div className="ellipse" id="ellipse2"></div>
        <div className="ellipse" id="ellipse3">
          <div className="letter">{alphabetArray[currentIndex]}</div>
        </div>
      </div>
      <div className="graphicDiv">
        <img src={graphic} alt="Listening" />
      </div>
      <div className="welcome-screen-sticky-button">
        <div className="getStartedButton" onClick={getStarted}>
          {t("get_started")}
        </div>
      </div>
    </div>
  );
});

export default SplashScreen;

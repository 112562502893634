import React, { useState, useRef } from "react";
import CustomSlider from "./components/CustomSlider/CustomSlider.js";
import "./components/CustomSlider/CustomSlider.css";
import AudioRecorder from "./AudioRecorder";

const TextResizer = ({
  hideSlider,
  setHideSlider,
  hideArrow,
  setHideArrow,
  showResultLoadingScreen,
  setShowResultLoadingScreen,
  showOrfTestScreen,
  setShowOrfTestScreen,
  audioBlobForAudioPlayer,
  setAudioBlobForAudioPlayer,
  paragraph,
  showOrfResultScreen,
  setShowOrfResultScreen,
  processedData,
  setProcessedData,
  assessmentId,
  setAssessmentId,
  showHomeScreen,
  setShowHomeScreen,
  studentName,
  setStudentName,
  orfResultStatus,
  setOrfResultStatus,
  selectedLaguage,
  setSelectedLaguage,
  classNum,
  setClassNum,
  schoolUdise,
  setSchoolUdise,
  saveClicked,
  setSaveClicked,
  isAudioUploading,
  setAudioUploading,
  uploadAudioStatus,
  setUploadAudioStatus,
  setProfession,
  assessmentLang,
  setGender,
  setSection,
  setSchoolShift,
}) => {
  const [fontSize, setFontSize] = useState(7.5); // Initial font size of 7.5vw
  const paragraphRef = useRef(null);

  const scrollParagraphToTop = () => {
    if (paragraphRef.current) {
      paragraphRef.current.scrollTo(0, 0);
    }
  };

  return (
    <div
      style={{
        width: "100%",
        boxSizing: "border-box",
      }}
    >
      <div
        ref={paragraphRef}
        style={{
          width: "100%",
          height: "84vh",
          boxSizing: "border-box",
          overflow: "scroll",
          overflowX: "hidden",
          paddingLeft: "1rem",
          paddingRight: "1rem",
          filter: hideSlider ? "none" : "blur(0.6vmax)",
          background: hideSlider ? "none" : "#F5F5F5",
        }}
      >
        <p
          style={{
            fontSize: `${fontSize}vw`,
            textOverflow: "ellipsis",
            textSizeAdjust: "inherit",
            paddingBottom: "350px",
            fontFamily:
              assessmentLang.value === "hi" ? "SakalBharati" : "Open Sans",
          }}
        >
          {paragraph}
        </p>
      </div>
      <div
        style={{
          position: "fixed",
          bottom: "0",
          left: "0",
          right: "0",
          zIndex: "5",
          background: "white",
          borderTop: "1px solid #C6C6C6",
          boxShadow: hideSlider
            ? "0px -50px 50px rgba(252, 252, 247, 0.9)"
            : "none",
        }}
      >
        <div>
          {!hideSlider && (
            <div
              style={{
                paddingLeft: "40px",
                paddingRight: "40px",
              }}
            >
              <CustomSlider
                min={5}
                max={10}
                defaultValue={fontSize}
                step={0.01}
                setSliderValue={setFontSize}
                marks={[
                  {
                    value: 5,
                    label: "A",
                  },
                  {
                    value: 7.5,
                  },
                  {
                    value: 10,
                    label: "A",
                  },
                ]}
              />
            </div>
          )}
          <div>
            <AudioRecorder
              hideSlider={hideSlider}
              setHideSlider={setHideSlider}
              hideArrow={hideArrow}
              setHideArrow={setHideArrow}
              showResultLoadingScreen={showResultLoadingScreen}
              setShowResultLoadingScreen={setShowResultLoadingScreen}
              showOrfTestScreen={showOrfTestScreen}
              setShowOrfTestScreen={setShowOrfTestScreen}
              audioBlobForAudioPlayer={audioBlobForAudioPlayer}
              setAudioBlobForAudioPlayer={setAudioBlobForAudioPlayer}
              showOrfResultScreen={showOrfResultScreen}
              setShowOrfResultScreen={setShowOrfResultScreen}
              processedData={processedData}
              setProcessedData={setProcessedData}
              assessmentId={assessmentId}
              setAssessmentId={setAssessmentId}
              showHomeScreen={showHomeScreen}
              setShowHomeScreen={setShowHomeScreen}
              studentName={studentName}
              setStudentName={setStudentName}
              orfResultStatus={orfResultStatus}
              setOrfResultStatus={setOrfResultStatus}
              selectedLaguage={selectedLaguage}
              setSelectedLaguage={setSelectedLaguage}
              classNum={classNum}
              setClassNum={setClassNum}
              schoolUdise={schoolUdise}
              setSchoolUdise={setSchoolUdise}
              saveClicked={saveClicked}
              setSaveClicked={setSaveClicked}
              isAudioUploading={isAudioUploading}
              setAudioUploading={setAudioUploading}
              uploadAudioStatus={uploadAudioStatus}
              setUploadAudioStatus={setUploadAudioStatus}
              setProfession={setProfession}
              setGender={setGender}
              scrollParagraphToTop={scrollParagraphToTop}
              setSection={setSection}
              setSchoolShift={setSchoolShift}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TextResizer;

import React from "react";
import VopaInputFieldsScreen from "./VopaInputFieldsScreen";
import useParamValues from "../../utils/useParamValues.js";

const VopaInputFieldsWrapper = ({ organisation, ...props }) => {
  let fieldsToShow = {
    showInstructorName: false,
    showPhoneNumber: false,
    showSchoolId: false,
    showGrade: false,
    showChildInfo: false,
    showProfession: false,
    showGender: false,
    showAge: false,
    showReadingLevel: false,
    showSection: false,
    showSchoolShift: false,
  };

  if (organisation === "pratham_demo_1") {
    fieldsToShow = {
      ...fieldsToShow,
      showInstructorName: true,
      showPhoneNumber: true,
      showGender: true,
      showAge: true,
      showReadingLevel: true,
      showChildInfo: true,
      showGrade: true,
    };
  } else if (
    organisation === "bihar_pilot_1" ||
    organisation === "bihar_demo_1" ||
    organisation === "bihar_pilot_2"
  ) {
    fieldsToShow = {
      ...fieldsToShow,
      showChildInfo: true,
      showSchoolId: true,
      showGrade: true,
      showGender: true,
    };
  } else if (organisation === "demo") {
    fieldsToShow = {
      ...fieldsToShow,
      showChildInfo: true,
      showSchoolId: true,
      showGrade: true,
      showProfession: true,
    };
  } else if (organisation === "delhi_mcd_1") {
    fieldsToShow = {
      ...fieldsToShow,
      showChildInfo: true,
      showGrade: true,
      showGender: true,
      showInstructorName: true,
      showSection: true,
      showSchoolShift: true,
      showSchoolId: true,
    };
  } else {
    fieldsToShow = {
      ...fieldsToShow,
      showChildInfo: true,
      showSchoolId: true,
      showGrade: true,
      showProfession: true,
    };
  }

  return <VopaInputFieldsScreen {...props} fieldsToShow={fieldsToShow} />;
};

export default VopaInputFieldsWrapper;

import { Dialog, DialogTitle } from "@mui/material";
import React from "react";
import "./GrantPermissionsStepsDialog.css";
import audioLocationPermissionsImg from "../../assets/audio_location_permissions_img.svg";
import { useTranslationSetup } from "../../utils/useTranslationSetup.js";
import LockIcon from "@mui/icons-material/Lock";
import TuneIcon from "@mui/icons-material/Tune";
import MicNoneIcon from "@mui/icons-material/MicNone";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import ToggleOffOutlinedIcon from "@mui/icons-material/ToggleOffOutlined";
import page_info from "../../assets/page_info.png";
import { useTranslation } from "react-i18next";

const GrantPermissionsStepsDialog = ({ isDialogOpen, setIsDialogOpen }) => {
  const { t } = useTranslationSetup();
  const { i18n } = useTranslation();
  const handleClose = () => {
    setIsDialogOpen(false);
  };
  return (
    <Dialog
      className="grant-permissions-steps-dialog"
      open={isDialogOpen}
      style={{ borderRadius: "20px" }}
    >
      <DialogTitle
        style={{
          textAlign: "center",
        }}
      >
        <div className="grant-permissions-steps-dialog-heading">
          {t("you_havent_allowed_access_to_location_mic")}
        </div>
        <div>
          <img
            src={audioLocationPermissionsImg}
            alt="audio-location-permissions-img"
          />
        </div>
        <div className="grant-permissions-steps-dialog-content">
          {t("reload_the_screen_after_following_steps")}
        </div>
        <div className="grant-permissions-steps">
          {i18n.language === "hi" ? (
            <>
              <span>{t("at_the_top_right_corner")} </span>
              <LockIcon
                sx={{
                  background: "#EFEFEF",
                  borderRadius: "2px",
                  fontSize: "14px",
                  margin: "0 4px -5px 4px",
                  padding: "2px",
                  color: "#636363",
                }}
              />
              <span>
                {t("click_on")}
                {t("pop_up_opens")}
              </span>
              <span>{t("permissions")}</span>
              <TuneIcon
                sx={{
                  background: "#EFEFEF",
                  borderRadius: "2px",
                  fontSize: "14px",
                  margin: "2px 4px -6px 4px",
                  padding: "2px",
                  color: "#636363",
                }}
              />
              <span>{t("click_on")}</span>
              <span>{t("microphone")}</span>
              <MicNoneIcon
                sx={{
                  background: "#EFEFEF",
                  borderRadius: "2px",
                  fontSize: "14px",
                  margin: "2px 4px -5px 4px",
                  padding: "2px",
                  color: "#636363",
                }}
              />{" "}
              /<span>{t("location")}</span>
              <LocationOnOutlinedIcon
                sx={{
                  background: "#EFEFEF",
                  borderRadius: "2px",
                  fontSize: "14px",
                  margin: "2px 4px -5px 4px",
                  padding: "2px",
                  color: "#636363",
                }}
              />
              <span>{t("click_on_button")}</span>
              <span>{t("click_outside_pop-up")} </span>
              <span>{t("reload")}</span>
            </>
          ) : (
            <>
              <span>{t("click_on")} </span>
              <LockIcon
                sx={{
                  background: "#EFEFEF",
                  borderRadius: "2px",
                  fontSize: "14px",
                  margin: "0 4px -5px 4px",
                  padding: "2px",
                  color: "#636363",
                }}
              />{" "}
              /
              <img
                src={page_info}
                alt="page_info"
                style={{
                  width: "14px",
                  height: "14px",
                  background: "#EFEFEF",
                  borderRadius: "2px",
                  fontSize: "14px",
                  margin: "2px 4px -5px 4px",
                  padding: "2px",
                  color: "#636363",
                }}
              />
              <span>
                {t("at_the_top_right_corner")} &gt; {t("click_on")}{" "}
                {t("permissions")}
              </span>
              <TuneIcon
                sx={{
                  background: "#EFEFEF",
                  borderRadius: "2px",
                  fontSize: "14px",
                  margin: "2px 4px -6px 4px",
                  padding: "2px",
                  color: "#636363",
                }}
              />
              <span>&gt; {t("click_on_button")} </span>
              <ToggleOffOutlinedIcon
                sx={{
                  background: "#EFEFEF",
                  borderRadius: "2px",
                  fontSize: "14px",
                  margin: "2px 4px -5px 4px",
                  padding: "2px",
                  color: "#636363",
                }}
              />
              <span>{t("against Microphone")}</span>
              <MicNoneIcon
                sx={{
                  background: "#EFEFEF",
                  borderRadius: "2px",
                  fontSize: "14px",
                  margin: "2px 4px -5px 4px",
                  padding: "2px",
                  color: "#636363",
                }}
              />
              <span> / {t("location")}</span>
              <LocationOnOutlinedIcon
                sx={{
                  background: "#EFEFEF",
                  borderRadius: "2px",
                  fontSize: "14px",
                  margin: "2px 4px -5px 4px",
                  padding: "2px",
                  color: "#636363",
                }}
              />
              <span>
                &gt; {t("click_outside_pop-up")} &gt; {t("reload")}
              </span>
            </>
          )}
        </div>
      </DialogTitle>
    </Dialog>
  );
};

export default GrantPermissionsStepsDialog;

import React from "react";
import "./ResultCard.css";

const ResultCard = ({
  heading,
  headingColor,
  bgColor,
  value,
  image,
  handleQuickSightCardOnClick = () => {},
}) => {
  return (
    <div
      className="result-card"
      style={{ borderColor: `${headingColor}`, backgroundColor: `${bgColor}` }}
      onClick={() => handleQuickSightCardOnClick(heading)}
    >
      <div className="result-heading" style={{ color: `${headingColor}` }}>
        {heading}
      </div>
      <div className="result-value-div">
        <img src={image} alt="" />
        <div className="result-value">{value}</div>
      </div>
    </div>
  );
};

export default ResultCard;

import { useTranslation } from "react-i18next";
import { useCallback, useEffect, useState } from "react";

export const useTranslationSetup = () => {
  const { t, i18n } = useTranslation();
  const [activeLanguage, setActiveLanguage] = useState(i18n.language);

  const setLanguage = (langCode) => {
    localStorage.setItem("app_language", langCode);
    i18n.changeLanguage(langCode);
    setActiveLanguage(langCode);
  };

  useEffect(() => {
    const storedLanguage = localStorage.getItem("app_language");
    if (storedLanguage && storedLanguage !== activeLanguage) {
      setLanguage(storedLanguage);
    }
  }, []);

  return { t, activeLanguage, setLanguage };
};

import React, { useEffect } from "react";
import DemoApp from "./apps/DemoApp";
import useParamValues from "./utils/useParamValues";
import { useTranslationSetup } from "./utils/useTranslationSetup";

function App() {
  const { organisation } = useParamValues();
  const { setLanguage } = useTranslationSetup();

  useEffect(() => {
    const storedLanguage = localStorage.getItem("app_language");
    if (
      organisation === "bihar_demo_1" ||
      organisation === "bihar_pilot_1" ||
      organisation === "bihar_pilot_2" ||
      organisation === "delhi_mcd_1"
    ) {
      setLanguage(storedLanguage || "hi");
    } else {
      setLanguage("en");
    }
  }, [organisation]);

  return (
    <React.Fragment>
      {(() => {
        switch (organisation) {
          case "demo":
            return <DemoApp organisation={organisation} />;
          case "bihar_demo_1":
            return <DemoApp organisation={organisation} />;
          default:
            return <DemoApp organisation={organisation} />;
        }
      })()}
    </React.Fragment>
  );
}

export default App;

import React from "react";
import "./InfoCards.css";
import { useTranslationSetup } from "../../utils/useTranslationSetup";

const InfoCards = ({ icon, info, value }) => {
  const { t } = useTranslationSetup();
  const getValueClassName = (value) => {
    switch (value) {
      case t("cwpm"):
        return "cwpm";
      case t("words_read_aloud"):
        return "wordsaloud";
        case t("accuracy"):
          return "accuracy";
      default:
        return "";
    }
  };

  const getValueParts = (value) => {
    const [part1, part2] = value.split("/"); // Split the value into parts

    return (
      <div>
        <span className="valuePart1">{part1}</span>
        {part2 ? <span className="valueSlash">/</span> : null}
        {part2 ? <span className="valuePart2">{part2}</span> : null}
      </div>
    );
  };
  return (
    <div className="infoCard">
      <div className="cardPart1">
        <div className="card-icon">
          <img src={icon} alt={info} />
        </div>
        <div className="card-info">{info}</div>
      </div>
      <div className={`card-value ${getValueClassName(info)}`}>
        {getValueParts(value)}
      </div>
    </div>
  );
};

export default InfoCards;

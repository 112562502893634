import { OrganisationMapping } from "./CommonUtils/constants";
const biharIds = new Set(["bihar_pilot_1", "bihar_demo_1", "bihar_pilot_2"]);
const biharPrathamIds = new Set([...biharIds, "pratham_demo_1"]);
const biharPrathamDelhiIds = new Set([...biharPrathamIds, "delhi_mcd_1"]);

export const getTeacherId = (organisation) => {
  if (biharIds.has(organisation)) {
    return "teacher_bihar_demo";
  } else if (organisation === "demo" || organisation === "generic_demo") {
    return "teacher_generic_demo";
  } else if (organisation === "pratham_demo_1") {
    return "teacher_pratham_demo";
  } else if (organisation === "delhi_mcd_1") {
    return "teacher_delhi_mcd_1";
  } else {
    return "teacher_generic_demo";
  }
};

export const getOrganisationId = (organisation) => {
  return OrganisationMapping[organisation] || "generic_demo";
};

export const getDeviceId = (organisation) => {
  if (biharIds.has(organisation)) {
    return "device_bihar_demo";
  } else if (organisation === "pratham_demo_1") {
    return "device_pratham_demo";
  } else if (organisation === "delhi_mcd_1") {
    return "device_delhi_mcd_1";
  } else if (organisation === "demo" || organisation === "generic_demo") {
    return "device_generic_demo";
  } else {
    return "device_generic_demo";
  }
};

const getOptions = (start, end) => {
  const options = [];
  for (let i = start; i <= end; i++) {
    options.push({
      value: i.toString(),
      label: i.toString(),
    });
  }
  return options;
};

export const getClassNumOptions = (organisation) => {
  if (biharPrathamIds.has(organisation)) {
    return getOptions(1, 8);
  } else if (organisation === "delhi_mcd_1") {
    return getOptions(2, 5);
  } else {
    return getOptions(3, 3);
  }
};

export const getNameLabel = (organisation, t) => {
  if (organisation === "pratham_demo_1") {
    return t("child_name_or_id");
  } else {
    return t("name");
  }
};

export const getGradeLabel = (organisation, t) => {
  if (biharPrathamIds.has(organisation)) {
    return t("class");
  } else if (organisation === "delhi_mcd_1") {
    return t("grade");
  } else {
    return t("grade_for_assessment");
  }
};

export const getSchoolIdLabel = (organisation, t) => {
  if (biharPrathamDelhiIds.has(organisation)) {
    return t("school_id");
  } else {
    return t("my_institution_is");
  }
};

export const getGrade = (organisation, grade) => {
  if (biharPrathamDelhiIds.has(organisation)) {
    return grade;
  } else {
    return 3;
  }
};

export const getTextFieldType = (organisation) => {
  if (biharPrathamIds.has(organisation)) {
    return "number";
  } else {
    return "text";
  }
};

export const getClassSectionOptions = (organisation) => {
  if (organisation === "delhi_mcd_1") {
    return [
      { value: "A", label: "A" },
      { value: "B", label: "B" },
      { value: "C", label: "C" },
      { value: "D", label: "D" },
      { value: "E", label: "E" },
      { value: "F", label: "F" },
      { value: "G", label: "G" },
    ];
  } else return;
};

export const getGenderOptions = (organisation, t) => {
  if (organisation === "delhi_mcd_1") {
    return [
      {
        value: "male",
        label: t("boy"),
      },
      {
        value: "female",
        label: t("girl"),
      },
    ];
  } else {
    return [
      {
        value: "male",
        label: t("male"),
      },
      {
        value: "female",
        label: t("female"),
      },
    ];
  }
};

export const getTeacherLabel = (organisation, t) => {
  if (organisation === "pratham_demo_1") {
    return t("instructor_name");
  } else {
    return t("teacher_name");
  }
};

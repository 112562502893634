import { UrlConstants } from "../utils/CommonUtils/constants";

const sendFeedback = (feedbackData, feedbackUrl) => {
  const options = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization:`Bearer ${UrlConstants.TOKEN}`,
    },
    body: JSON.stringify(feedbackData),
  };

  return fetch(feedbackUrl, options).then((res) => {
    if (!res.ok) {
      return {
        status: "ERROR",
        data: "Error occurred...",
      };
    } else {
      return {
        status: "Ok",
        data: res.json(),
      };
    }
  });
};

export default sendFeedback;

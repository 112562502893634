import React, { useEffect, useState, useRef } from "react";
import CustomTextField from "../../components/CustomTextField/CustomTextField";
import CustomDropDown from "../../components/CustomDropDown/CustomDropDown";
import "./VopaInputFieldsScreen.css";
import { Button } from "@mui/material";
import { useTranslationSetup } from "../../utils/useTranslationSetup.js";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import useParamValues from "../../utils/useParamValues.js";
import {
  getClassNumOptions,
  getClassSectionOptions,
  getGenderOptions,
  getGradeLabel,
  getNameLabel,
  getSchoolIdLabel,
  getTeacherLabel,
  getTextFieldType,
} from "../../utils/helper.js";
import { SchoolIdName } from "../../utils/CommonUtils/constants.js";

const VopaInputFieldsScreen = ({
  name,
  setName,
  classNum,
  setClassNum,
  schoolUdise,
  setSchoolUdise,
  medium,
  setMedium,
  profession,
  setProfession,
  showVopaInputFieldsScreen,
  setShowVopaInputFieldsScreen,
  setShowAssessmentLangScreen,
  setStudentId,
  gender,
  setGender,
  age,
  setAge,
  instructorName,
  setInstructorName,
  phoneNumber,
  setPhoneNumber,
  readingLevel,
  setReadingLevel,
  setRequestBody,
  section,
  setSection,
  schoolShift,
  setSchoolShift,
  fieldsToShow,
}) => {
  const { t } = useTranslationSetup();
  const [isDisabled, setIsDisabled] = useState(true);
  const { organisation } = useParamValues();

  const schoolIdNameOptions = SchoolIdName.map((school) => ({
    value: school.schoolId,
    label: `${school.schoolName} (${school.schoolId})`,
  }));

  const handleFieldChange = (value, setFieldValue) => {
    const filteredValue = value.replace(/[^a-zA-Z\s]/g, "");
    setFieldValue(filteredValue);
  };

  const onClickNext = () => {
    setStudentId(
      `${
        profession === "student" ? "ST" : profession === "teacher" ? "TE" : "OT"
      }D${new Date().getTime()}`
    );
    const feedbackDetails = {
      name,
      classNum,
      schoolUdise,
      gender,
      age,
      instructorName,
      phoneNumber,
      readingLevel,
    };

    setRequestBody(feedbackDetails);
    setShowVopaInputFieldsScreen(false);
    setShowAssessmentLangScreen(true);
  };
  useEffect(() => {
    let isDisabled = true;
    if (organisation === "pratham_demo_1") {
      isDisabled = !(
        name !== "" &&
        classNum !== "" &&
        gender !== "" &&
        age !== "" &&
        instructorName !== "" &&
        phoneNumber !== ""
      );
    } else if (
      organisation === "bihar_demo_1" ||
      organisation === "bihar_pilot_1" ||
      organisation === "bihar_pilot_2"
    ) {
      isDisabled = !(
        schoolUdise !== "" &&
        classNum !== "" &&
        name !== "" &&
        gender !== ""
      );
    } else if (organisation === "demo" || organisation === "generic_demo") {
      isDisabled = !(
        schoolUdise !== "" &&
        classNum !== "" &&
        name !== "" &&
        profession !== ""
      );
    } else if (organisation === "delhi_mcd_1") {
      isDisabled = !(
        schoolUdise !== "" &&
        classNum !== "" &&
        name !== "" &&
        gender !== "" &&
        section !== "" &&
        instructorName !== "" &&
        schoolShift !== ""
      );
    } else {
      isDisabled = !(
        schoolUdise !== "" &&
        classNum !== "" &&
        name !== "" &&
        profession !== ""
      );
    }
    setIsDisabled(isDisabled);
  }, [
    organisation,
    name,
    classNum,
    schoolUdise,
    gender,
    age,
    instructorName,
    phoneNumber,
    readingLevel,
    profession,
    section,
    schoolShift,
  ]);

  return (
    <div className="vopa-input-field-screen-container">
      <div className="demo-input-field-heading-wrapper">
        <div className="vopa-input-field-screen-heading-text">
          {t("new_student")}
        </div>
        <div className="vopa-input-field-screen-subheading-text">
          {t("let_us_get_to_know_you_better_before_your_ORF_assessment")}
        </div>
      </div>
      <div className="demo-input-fields-container">
        {fieldsToShow.showInstructorName && (
          <div>
            <div className="input-label">
              {getTeacherLabel(organisation, t)}
              <span className="asterisk">*</span>
            </div>
            <CustomTextField
              inputValue={instructorName}
              setInputValue={(value) =>
                handleFieldChange(value, setInstructorName)
              }
              autoComplete="on"
            />
          </div>
        )}
        {fieldsToShow.showPhoneNumber && (
          <div>
            <div className="input-label">
              {t("instructor_phone_number")}
              <span className="asterisk">*</span>
            </div>
            <CustomTextField
              inputValue={phoneNumber}
              setInputValue={setPhoneNumber}
              type="number"
            />
          </div>
        )}
        {fieldsToShow.showChildInfo && (
          <div>
            <div className="input-label">
              {getNameLabel(organisation, t)}
              <span className="asterisk">*</span>
            </div>
            <CustomTextField
              inputValue={name}
              setInputValue={(value) => handleFieldChange(value, setName)}
              // autoComplete="on"
            />
          </div>
        )}
        {fieldsToShow.showProfession && (
          <div>
            <div className="input-label">
              {t("i_am_a")}
              <span className="asterisk">*</span>
            </div>
            <CustomDropDown
              selectedValue={profession}
              setSelectedValue={setProfession}
              options={[
                {
                  value: "student",
                  label: "Student",
                },
                {
                  value: "teacher",
                  label: "Teacher",
                },
                {
                  value: "parent",
                  label: "Parent/Guardian",
                },
              ]}
            />
          </div>
        )}
        {fieldsToShow.showGender && (
          <div>
            <div className="input-label">
              {t("gender")}
              <span className="asterisk">*</span>
            </div>
            <CustomDropDown
              selectedValue={gender}
              setSelectedValue={setGender}
              options={getGenderOptions(organisation, t)}
            />
          </div>
        )}
        {fieldsToShow.showGrade && (
          <div>
            <div className="input-label">
              {getGradeLabel(organisation, t)}
              <span className="asterisk">*</span>
            </div>
            <CustomDropDown
              selectedValue={classNum}
              setSelectedValue={setClassNum}
              options={getClassNumOptions(organisation)}
            />
          </div>
        )}

        {fieldsToShow.showSection && (
          <div>
            <div className="input-label">
              {t("section")}
              <span className="asterisk">*</span>
            </div>
            <CustomDropDown
              selectedValue={section}
              setSelectedValue={setSection}
              options={getClassSectionOptions(organisation)}
            />
          </div>
        )}

        {fieldsToShow.showSchoolId && (
          <div>
            <div className="input-label">
              {getSchoolIdLabel(organisation, t)}
              <span className="asterisk">*</span>
            </div>
            {organisation === "delhi_mcd_1" ? (
              <CustomDropDown
                selectedValue={schoolUdise}
                setSelectedValue={setSchoolUdise}
                options={schoolIdNameOptions}
              />
            ) : (
              <CustomTextField
                inputValue={schoolUdise}
                setInputValue={setSchoolUdise}
                type={getTextFieldType(organisation)}
              />
            )}
          </div>
        )}

        {fieldsToShow.showSchoolShift && (
          <div>
            <div className="input-label">
              {t("school_shift")}
              <span className="asterisk">*</span>
            </div>
            <CustomDropDown
              selectedValue={schoolShift}
              setSelectedValue={setSchoolShift}
              options={[
                { value: "Morning (Girls)", label: "Morning (Girls)" },
                { value: "Evening (Boys)", label: "Evening (Boys)" },
                { value: "General (Co-ed)", label: "General (Co-ed)" },
              ]}
            />
          </div>
        )}

        {fieldsToShow.showAge && (
          <div>
            <div className="input-label">
              {t("age")}
              <span className="asterisk">*</span>
            </div>
            <CustomTextField
              inputValue={age}
              setInputValue={setAge}
              type="number"
            />
          </div>
        )}

        {fieldsToShow.showReadingLevel && (
          <div>
            <div className="input-label">{t("reading_level")}</div>
            <CustomTextField
              inputValue={readingLevel}
              setInputValue={setReadingLevel}
              autoComplete="on"
            />
          </div>
        )}
      </div>
      <div className="sticky-button-container">
        <Button
          variant="contained"
          endIcon={<ArrowForwardIcon />}
          style={{
            height: "46px",
            width: "70%",
            textTransform: "none",
            borderRadius: "12px",
            color: "#fff",
            fontWeight: "400",
            fontSize: "18px",
            background: isDisabled ? "" : "#EB770C",
          }}
          disableElevation
          disabled={isDisabled}
          onClick={onClickNext}
        >
          {t("next")}
        </Button>
      </div>
    </div>
  );
};

export default VopaInputFieldsScreen;

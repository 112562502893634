import { Box, IconButton, Typography } from "@mui/material";
import KeyboardBackspaceRoundedIcon from "@mui/icons-material/KeyboardBackspaceRounded";
import ShareOutlinedIcon from "@mui/icons-material/ShareOutlined";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import useCloseWebview from "./utils/useCloseWebview";
const Header = ({
  background,
  color,
  hideArrow,
  isSticky,
  heading,
  showShareDownloadIcon,
  isSelectLanguage,
  handleOnClickBackButton,
}) => {
  return (
    <Box
      sx={{
        background: background ? background : "#fff",
        color: color ? color : "000",
        position: isSticky ? "sticky" : "static",
        top: 0,
        left: 0,
        right: 0,
        zIndex: isSticky ? 99 : 0,
      }}
    >
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent:
            hideArrow && !showShareDownloadIcon ? "center" : "space-between",
          alignItems: "center",
          padding: "0.5rem",
          boxSizing: "border-box",
        }}
      >
        {!hideArrow && (
          <Box sx={{ width: "50px" }}>
            <IconButton
              onClick={() => {
                // closeWebView()
                if (isSelectLanguage) {
                  handleOnClickBackButton();
                }
              }}
            >
              <KeyboardBackspaceRoundedIcon
                style={{ color: color ? color : "#fff" }}
              />
            </IconButton>
          </Box>
        )}
        <Typography
          sx={{
            textAlign: "center",
            color: color ? color : "#fff",
            fontSize: "18px",
            fontWeight: "600",
            padding: "5px 0px",
            fontFamily: "Open Sans",
            marginLeft: showShareDownloadIcon ? "16px" : "0px",
            wordBreak: "break-word",
            overflowWrap: "break-word",
          }}
        >
          {heading}
        </Typography>
        {!hideArrow && <Box sx={{ width: "50px" }}></Box>}
        {showShareDownloadIcon && (
          <Box
            style={{
              display: "flex",
              marginRight: "16px",
            }}
          >
            <IconButton
              style={{
                padding: "0px",
              }}
            >
              <ShareOutlinedIcon
                style={{ color: color ? color : "#fff" }}
                onClick={() => {
                  // sendShareFileEvent()
                }}
              />
            </IconButton>
            <IconButton
              style={{
                padding: "0px",
                marginLeft: "20px",
              }}
            >
              <FileDownloadOutlinedIcon
                style={{ color: color ? color : "#fff", fontSize: "1.8rem" }}
                onClick={() => {
                  // sendDownloadFileEvent()
                }}
              />
            </IconButton>
          </Box>
        )}
      </Box>
      <Box
        sx={{
          padding: "0.5rem 0",
          width: "100%",
          borderTopRightRadius: "12px",
          borderTopLeftRadius: "12px",
          background: "#fff",
        }}
      ></Box>
    </Box>
  );
};
export default Header;

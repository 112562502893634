import { TextField } from "@mui/material";
import "./CustomTextField.css";

const CustomTextField = ({
  inputValue,
  setInputValue,
  label,
  type,
  autoComplete,
}) => {
  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  return (
    <TextField
      style={{
        width: "100%",
      }}
      className={`outlined-textfield ${inputValue ? "filled" : ""}`}
      placeholder={label}
      variant="outlined"
      value={inputValue}
      onChange={handleInputChange}
      autoComplete={autoComplete}
      type={type}
    />
  );
};

export default CustomTextField;

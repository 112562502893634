import React, { useEffect, useState } from "react";
import "./DetailedResultCard.css";
import dropDown from "../../assets/drop-down.svg";
import { useTranslationSetup } from "../../utils/useTranslationSetup";

const DetailedResultCard = ({
  index,
  heading,
  subHeading,
  bgColor,
  icon,
  innerBorderColor,
  words,
  scrollCardIndex,
}) => {
  const { t } = useTranslationSetup();
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (index === scrollCardIndex) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [index, scrollCardIndex, setOpen]);

  const dropdown = () => {
    setOpen(!open);
  };

  return (
    <div
      className="detailed-result-item-wrapper"
      style={{ backgroundColor: `${bgColor}` }}
    >
      {heading === t("incorrect_words") ? (
        <div className="detailed-result-item-attention">
          {t("needs_attention")}
        </div>
      ) : null}
      <div className="detailed-result-item">
        <div
          className="detailed-result-item-icon"
          style={{ borderColor: `${innerBorderColor}` }}
        >
          <img src={icon} alt={heading} />
        </div>
        <div className="detailed-result-item-header">
          <div className="detailed-result-item-heading">{heading}</div>
          <div className="detailed-result-item-subheading">{subHeading}</div>
        </div>
        <div className="detailed-result-item-action" onClick={dropdown}>
          <div className="detailed-result-item-btn">
            <img src={dropDown} alt="drop-down" />
          </div>
          <div className="detailed-result-item-subheading">
            {t("see_all_words")}
          </div>
        </div>
      </div>
      {open && (
        <div className="detailed-result-item-opendropdown">
          {words.map((word, idx) => (
            <div key={idx} className="detailed-result-item-opendropdown-word">
              {word}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default DetailedResultCard;

import React, { useState } from "react";
import "./AssessmentSaveAudioConfirmationDrawer.css";
import { Button, SwipeableDrawer } from "@mui/material";
import AudioPlayer from "../../AudioPlayer";
import { useTranslationSetup } from "../../utils/useTranslationSetup";

const AssessmentSaveAudioConfirmationDrawer = ({
  openDrawer,
  setOpenDrawer,
  audioBlob,
  setAudioBlob,
  onClickRetakeButton,
  onClickSaveButton,
}) => {
  const [assessmentAudioElementRef, setAssessmentAudioElementRef] =
    useState(null);
  const { t } = useTranslationSetup();

  const toggleDrawer = (open) => () => {
    if (!open) {
      return;
    }
    setOpenDrawer(open);
  };

  const handleOnClickSaveButton = () => {
    if (assessmentAudioElementRef !== null) {
      assessmentAudioElementRef.current?.pause();
    }
    onClickSaveButton();
  };

  return (
    <SwipeableDrawer
      anchor="bottom"
      open={openDrawer}
      onClose={toggleDrawer(false)}
      onOpen={toggleDrawer(true)}
      transitionDuration={1000}
      onTouchStart={(event) => {
        event.nativeEvent.defaultMuiPrevented = true;
      }}
      sx={{
        background: "rgba(0, 0, 0, 0.25)",
      }}
      className="save-audio-confirmation-drawer"
    >
      <div className="listen-to-recording-text">{t("listen_to_recording")}</div>
      {audioBlob && (
        <div
          style={{
            padding: "6px 14px 6px 8px",
            background: "#E477141C",
            borderRadius: "12px",
            margin: "0px 5% 30px",
          }}
        >
          <AudioPlayer
            audioBlobForAudioPlayer={audioBlob}
            setAudioBlobForAudioPlayer={setAudioBlob}
            testedAudioElementRef={assessmentAudioElementRef}
            setTestedAudioElementRef={setAssessmentAudioElementRef}
          />
        </div>
      )}
      <div
        style={{
          borderRadius: "12px",
          border: "0.5px solid #DBDBDB",
          margin: "0 5% 5vh",
        }}
      >
        <div className="save-audio-confirmation-text">
          {t("are_you_sure_to_submit_recording")}
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            width: "100%",
          }}
        >
          <Button
            style={{
              height: "49px",
              background: "#2573D9",
              width: "100%",
              borderRadius: "12px",
              textTransform: "capitalize",
              margin: "0px 14px 20px",
              fontFamily: "Poppins",
              fontWeight: "500",
              fontSize: "16px",
              letterSpacing: "-0.304px",
              color: "#FFF",
              backgroundColor: "#E47714",
            }}
            disableElevation
            variant="contained"
            onClick={handleOnClickSaveButton}
          >
            {t("submit")}
          </Button>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            width: "100%",
          }}
        >
          <Button
            style={{
              height: "49px",
              width: "100%",
              borderRadius: "12px",
              textTransform: "capitalize",
              margin: "0px 14px 32px",
              fontFamily: "Poppins",
              fontWeight: "500",
              fontSize: "16px",
              letterSpacing: "-0.304px",
              color: "#E47714",
              border: "1px solid #E47714",
            }}
            disableElevation
            variant="outlined"
            onClick={() => {
              onClickRetakeButton();
            }}
          >
            {t("retake")}
          </Button>
        </div>
      </div>
    </SwipeableDrawer>
  );
};

export default AssessmentSaveAudioConfirmationDrawer;

import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import enTranslation from "./translations/en.json";
import mrTranslation from "./translations/mr.json";
import hiTranslation from "./translations/hi.json";

const resources = {
  en: {
    translation: enTranslation,
  },
  mr: {
    translation: mrTranslation,
  },
  hi: {
    translation: hiTranslation,
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: "hi", // Set the default language here
  fallbackLng: "hi", // Fallback language if the desired language is not available
  interpolation: {
    escapeValue: false, // React already escapes values to prevent XSS attacks
  },
});

export default i18n;

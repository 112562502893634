export const QueryParams = Object.freeze({
  ORGNISATION: "organisation",
  APP_LANGUAGE: "app_language",
});

export const OrganisationMapping = Object.freeze({
  demo: "generic_demo",
  pratham_demo_1: "pratham_demo_1",
  bihar_demo_1: "bihar_pilot_1",
  bihar_pilot_1: "bihar_pilot_1",
  bihar_pilot_2: "bihar_pilot_2",
  delhi_mcd_1: "delhi_mcd_1",
});

export const UrlConstants = Object.freeze({
  BASE_URL:
    // process.env.NODE_ENV === "production"
    //   ?
    "https://orf-orchestrator.wadhwaniai.org/",
    // :
    // "https://orf-orchestrator-staging.wadhwaniai.org/",
  // "https://vachan-samiksha.wadhwaniai.org/",
  // "https://temp-orf-service.wadhwaniai.org/",
  BASE_PATH_URL: "api/v1/orf/",
  ASSESSMENT: "assessment",
  TOKEN:
    "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJ0ZXN0X3VzZXIiLCJleHAiOjE3NTUwNzQ3MDJ9.Jm6DnqHRwek7gPeGqCOdenZZPRUBPbLehyn03WqVQts",
});

export const SchoolIdName = [
  {
    schoolId: "1556029",
    schoolName: "HARI NAGAR L BLOCK",
  },
  {
    schoolId: "1556030",
    schoolName: "GURU NANAK PURA",
  },
  {
    schoolId: "1556031",
    schoolName: "PARTAP NAGAR",
  },
  {
    schoolId: "1556039",
    schoolName: "TIHAR NO 2",
  },
  {
    schoolId: "1152530",
    schoolName: "KARDAMPURI-I",
  },
  {
    schoolId: "1152531",
    schoolName: "KARDAMPURI-II",
  },
  {
    schoolId: "1152074",
    schoolName: "ASHOK NAGAR",
  },
  {
    schoolId: "1152075",
    schoolName: "ASHOK NAGAR",
  },
  {
    schoolId: "1958016",
    schoolName: "ANDREWJ GANJ",
  },
  {
    schoolId: "1958006",
    schoolName: "BAPU PARK",
  },
  {
    schoolId: "1958010",
    schoolName: "SOUTH EXTN PART I",
  },
  {
    schoolId: "1958024",
    schoolName: "LAJPAT NAGAR PART II",
  },
  {
    schoolId: "2161423",
    schoolName: "BAGH KARE KHAN",
  },
  {
    schoolId: "2161047",
    schoolName: "PRATAP NAGAR",
  },
  {
    schoolId: "1152095",
    schoolName: "JYOTI COLONY",
  },
  {
    schoolId: "2161055",
    schoolName: "BARA HINDU RAO",
  },
  {
    schoolId: "1958003",
    schoolName: "SEWA NAGAR N BLOCK",
  },
  {
    schoolId: "1556061",
    schoolName: "TILAK NAGAR NO 2",
  },
  {
    schoolId: "1556058",
    schoolName: "TILAK NAGAR NO 3",
  },
  {
    schoolId: "2160316",
    schoolName: "INDER LOK",
  },
  {
    schoolId: "2161422",
    schoolName: "JAI RANI BAGH PADAM NAGAR",
  },
  {
    schoolId: "1556043",
    schoolName: "HARI NAGAR GHANTA GHAR",
  },
  {
    schoolId: "1152679",
    schoolName: "DDA FLAT LONI ROAD",
  },
  {
    schoolId: "1958011",
    schoolName: "SOUTH EXTN NEW PART II",
  },
];

import { MenuItem, TextField } from "@mui/material";
import React from "react";
import "./CustomDropDown.css";

const CustomDropDown = ({
  selectedValue,
  setSelectedValue,
  options,
  label,
}) => {
  const handleSelectChange = (event) => {
    setSelectedValue(event.target.value);
  };

  return (
    <TextField
      style={{
        width: "100%",
      }}
      className={`outlined-drop-down-select ${selectedValue ? "filled" : ""}`}
      select
      label={label}
      value={selectedValue}
      onChange={handleSelectChange}
    >
      {options.map((option) => (
        <MenuItem key={option.value} value={option.value} className="drop-down-options">
          {option.label}
        </MenuItem>
      ))}
    </TextField>
  );
};

export default CustomDropDown;

import React from "react";
import "./VopaInstructionComponent.css";
import { useTranslationSetup } from "../../utils/useTranslationSetup";

const VopaInstructionComponent = ({ heading, content, gif }) => {
  const { t } = useTranslationSetup();
  return (
    <div>
      <div>{gif}</div>
      <div className="vopa-instruction-heading">{heading}</div>
      <div className="vopa-instruction-desc">
        {content}
        {heading === "4. Finish the Paragraph" ? (
          <span className="vopa-instruction-desc-span">
            {" "}
            {t("read_it_aloud")}
          </span>
        ) : null}
      </div>
    </div>
  );
};

export default VopaInstructionComponent;
